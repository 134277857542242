// src/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';


const firebaseConfig = {
  apiKey: "AIzaSyBFuX24z609hGK3iFXSvHLCcRYaMP144iE",
  authDomain: "auth-asertis-ia.firebaseapp.com",
  projectId: "auth-asertis-ia",
  storageBucket: "auth-asertis-ia.appspot.com",
  messagingSenderId: "38971852934",
  appId: "1:38971852934:web:952d27d17cbacc87ff6907",
  measurementId: "G-KHWNPTKTRH"
};

// Inicializar la aplicación de Firebase
const app = initializeApp(firebaseConfig);

// Obtener el objeto de autenticación
const auth = getAuth(app);

// Exportar el objeto de autenticación y la configuración de Firebase
export { auth, firebaseConfig };
