// TypingText.js
import React, { useState, useEffect } from 'react';
import './TypingText.css'; // Importa el archivo CSS

const TypingText = ({ text, typingSpeed = 150 }) => {
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (typeof text !== 'string') {
      console.error('El texto proporcionado no es una cadena');
      return;
    }

    let index = 0;
    const intervalId = setInterval(() => {
      if (index < text.length) {
        setDisplayedText((prev) => prev + text.charAt(index));
        index++;
      } else {
        clearInterval(intervalId);
      }
    }, typingSpeed);
  
    return () => clearInterval(intervalId);
  }, [text, typingSpeed]);

  return (
    <div className="typingText">
      {displayedText}
    </div>
  );
};

export default TypingText;
