import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSun,
  faMoon,
  faArrowUp,
  faStop,
  faMicrophone,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import './ChatComponent.css'; 

const ChatComponent = () => {
  const [state, setState] = useState({
    input: "",
    messages: [],
    isSending: false,
    isListening: false,
    recognition: null,
    theme: "dark",
    textHistory: [],
    indexName: "", // Estado para almacenar el IndexName
    context: "", // Nuevo estado para almacenar el contexto
  });
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [state.messages]);

  function getIndexNameAndContextFromUrl() {
    const urlParams = new URLSearchParams(window.location.search);
    const context = urlParams.get('context') || '';
    const urlPath = window.location.pathname;
    const segments = urlPath.split('/').filter(segment => segment.length > 0);
    const indexName = segments.length > 0 ? segments[segments.length - 1] : '';
    return { indexName, context };
  }

  useEffect(() => {
    const { indexName, context } = getIndexNameAndContextFromUrl();
    setState((prevState) => ({
      ...prevState,
      indexName,
      context, // Actualiza el contexto en el estado
    }));
  }, []);

  const handleSend = async () => {
    const { input, textHistory, indexName, context } = state;
    if (!input.trim()) return;

    setState((prevState) => ({
      ...prevState,
      isSending: true,
      messages: [...prevState.messages, { text: input, sender: "user" }],
      input: "",
    }));

    try {
      const urlIA = process.env.REACT_APP_IA_ASERTIS;
      const response = await axios.post(
        urlIA,
        {
          text_input: input,
          text_history: textHistory.join(" "),
          context: context, // Usa el contexto extraído de la URL
          way_respond: "Debes responder de forma amable y no incluir el way_respond",
        },
        {
          headers: {
            IndexName: indexName,
            "Content-Type": "application/json",
            Authorization: 'Basic QXNlcnRpc0JQTzpGZW5hbGNvMjAyNCo=',
            nameModel: 'gpt-4o'
          },
        }
      );

      const botResponse = response.data.respuesta;
      const similarity = response.data.similitud;

      if (similarity > 0.15) {
        setState((prevState) => ({
          ...prevState,
          textHistory: [...prevState.textHistory, botResponse],
          messages: [
            ...prevState.messages,
            { text: botResponse, sender: "bot" },
          ],
          isSending: false,
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          messages: [
            ...prevState.messages,
            { text: botResponse, sender: "bot" },
          ],
          isSending: false,
        }));
      }
    } catch (error) {
      console.error(
        "Error fetching the response:",
        error.response || error.message
      );
      setState((prevState) => ({
        ...prevState,
        messages: [
          ...prevState.messages,
          { text: "Error al obtener respuesta", sender: "bot" },
        ],
        isSending: false,
      }));
    }
  };

  const toggleTheme = () => {
    setState((prevState) => ({
      ...prevState,
      theme: prevState.theme === "light" ? "dark" : "light",
    }));
  };

  const startRecognition = () => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognition) {
      alert("Tu navegador no soporta reconocimiento de voz.");
      return;
    }

    const recognitionInstance = new SpeechRecognition();
    recognitionInstance.lang = "es-ES";
    recognitionInstance.interimResults = true;
    recognitionInstance.continuous = true;

    recognitionInstance.onstart = () =>
      setState((prevState) => ({ ...prevState, isListening: true }));
    recognitionInstance.onresult = (event) => {
      let transcript = "";
      for (let i = event.resultIndex; i < event.results.length; i++) {
        transcript += event.results[i][0].transcript;
      }
      setState((prevState) => ({ ...prevState, input: transcript }));
    };
    recognitionInstance.onerror = (event) =>
      console.error("Error en el reconocimiento de voz:", event.error);
    recognitionInstance.onend = () => {
      if (state.isListening) {
        recognitionInstance.start();
      }
    };

    setState((prevState) => ({
      ...prevState,
      recognition: recognitionInstance,
    }));
    recognitionInstance.start();
  };

  const stopRecognition = () => {
    if (state.recognition) {
      state.recognition.stop();
      setState((prevState) => ({ ...prevState, isListening: false }));

      if (state.input.trim()) {
        handleSend().finally(() => {
          setState((prevState) => ({ ...prevState, input: "" }));
        });
      } else {
        setState((prevState) => ({ ...prevState, input: "" }));
      }
    }
  };

  const handleListen = () => {
    state.isListening ? stopRecognition() : startRecognition();
  };

  const { input, messages, isSending, isListening, theme } = state;

  return (
    <div className={`container ${theme}`}>
      <div className="chat-messages">
        {messages.map((msg, index) => (
          <div
            key={index}
            className={`message ${
              msg.sender === "bot" ? "bot-response-container" : ""
            }`}
          >
            {msg.text}
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>
      <div className="input-container">
        <textarea
          className="input-field"
          value={input}
          onChange={(e) =>
            setState((prevState) => ({ ...prevState, input: e.target.value }))
          }
          onKeyPress={(e) => {
            if (e.key === "Enter" && !isSending) {
              handleSend();
            }
          }}
          rows={1}
        />
        <button
          className={`send-button ${isSending ? "sending" : ""}`}
          onClick={handleSend}
          disabled={isSending}
        >
          <FontAwesomeIcon icon={isSending ? faStop : faArrowUp} />
        </button>
        <button
          className={`microphone-button ${isListening ? "listening" : ""}`}
          onClick={handleListen}
          disabled={isSending}
          aria-label={
            isListening
              ? "Detener reconocimiento de voz"
              : "Iniciar reconocimiento de voz"
          }
        >
          <FontAwesomeIcon
            icon={faMicrophone}
            style={{ color: isListening ? "red" : "black" }}
          />
        </button>
        <button
          className="theme-button"
          onClick={toggleTheme}
          aria-label="Cambiar tema a oscuro/claro"
        >
          <FontAwesomeIcon icon={theme === "light" ? faMoon : faSun} />
        </button>
      </div>
    </div>
  );
};

export default ChatComponent;
