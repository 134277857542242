import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebaseConfig';
import LoadingSpinner from './LoadingSpinner'; // Importa el componente de carga

const ProtectedRoute = ({ children }) => {
  const [user, loading] = useAuthState(auth);



  if (loading) return <LoadingSpinner />; // Usa el componente de carga aquí
  if (!user) return <Navigate to="/login" />; // Redirige al login si no está autenticado

  return children;
};

export default ProtectedRoute;
