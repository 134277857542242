import React from 'react';
import { signOut } from 'firebase/auth';
import { auth } from './components/auth/firebaseConfig'; 
import './Header.css'; 
const Header = () => {
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        // Redirigir al login o hacer alguna acción post-logout
        window.location.href = '/login';
      })
      .catch((error) => {
        console.error('Error al cerrar sesión:', error);
      });
  };

  return (
    <header className="appHeader">
      <h1>Asertis BPO-AI</h1>
      <button className="logout-button" onClick={handleLogout}>Cerrar sesión</button>
    </header>
  );
};

export default Header;
