import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { signOut } from "firebase/auth";
import { auth } from "./components/auth/firebaseConfig";
import ChatComponent from "./components/chatComponentCopilot/ChatComponent";
import UploadFilePDF from "./components/uploadpdfvectorial/uploadfilepdf";
import Login from "./components/auth/Login";
import ProtectedRoute from "./components/auth/ProtectedRoute";
import Header from "./Header";
import TypingText from "./TypingText";
import "./App.css";

const App = () => {
  useEffect(() => {
    let timeout;

    const resetTimeout = () => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        signOut(auth)
          .then(() => {
            window.location.href = "/login";
          })
          .catch((error) => {
            console.error("Error al cerrar sesión por inactividad:", error);
          });
      }, 60 * 60 * 1000);
    };

    window.addEventListener("mousemove", resetTimeout);
    window.addEventListener("keydown", resetTimeout);
    resetTimeout();

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("mousemove", resetTimeout);
      window.removeEventListener("keydown", resetTimeout);
    };
  }, []);

  return (
    <Router>
      <div className="appContainer">
        <Header />
        <div className="typing-text-container">
          <TypingText
            text={`Biienvenido a Asertis BPO-AI, estoy aquí para facilitar la optimización y el éxito en la gestión de operaciones.`}
            typingSpeed={40}
          />
        </div>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route
            path="/fundacionwwb"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/expomotor"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/asertisbpoia"
            element={
              <ProtectedRoute>
                <ChatComponent />
              </ProtectedRoute>
            }
          />
          <Route
            path="/upload-pdf"
            element={
              <ProtectedRoute>
                <UploadFilePDF />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
